// Generated by Framer (ae50a0d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["vdbrox2hU", "Hs7OsjnSv"];

const serializationHash = "framer-GgbRG"

const variantClassNames = {Hs7OsjnSv: "framer-v-fe29ka", vdbrox2hU: "framer-v-b3uww5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Closed: "vdbrox2hU", Open: "Hs7OsjnSv"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vdbrox2hU"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vdbrox2hU", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-b3uww5", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"vdbrox2hU"} ref={refBinding} style={{...style}} {...addPropertyOverrides({Hs7OsjnSv: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-pey03s"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"q9DUuvqtD"} style={{rotate: 0}} variants={{Hs7OsjnSv: {rotate: -180}}}><SVG className={"framer-behk4b"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"YqUZNm_jm"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"#aaa\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={9482190474} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GgbRG.framer-17aqsjk, .framer-GgbRG .framer-17aqsjk { display: block; }", ".framer-GgbRG.framer-b3uww5 { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-GgbRG .framer-pey03s { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-GgbRG .framer-behk4b { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Hs7OsjnSv":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameruedap1yX5: React.ComponentType<Props> = withCSS(Component, css, "framer-GgbRG") as typeof Component;
export default Frameruedap1yX5;

Frameruedap1yX5.displayName = "Caret";

Frameruedap1yX5.defaultProps = {height: 14, width: 14};

addPropertyControls(Frameruedap1yX5, {variant: {options: ["vdbrox2hU", "Hs7OsjnSv"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameruedap1yX5, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})